form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        padding: 20px 15px 20px 50px;
        // box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
        // border: none;
        border: 0.5px solid rgba(74, 131, 122, 0.15);
        margin-bottom: 15px;
        // border-radius: 5px;
        // font-size: 2rem;

        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #444B51;

        // line-height: 1;
        background-color: #F6F7F9;
        @include media-breakpoint-up(md) {
            padding: 20px 15px 20px 70px;
        }
        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }
        &:focus {
            background-color: #fff !important;
            border: 1px solid #04B882;
        }
        &:focus-visible{
            background-color: #fff !important;
            border: 1px solid #04B882 !important;
        }
    }

    textarea {
        height: 210px !important;
    }

    select {
        appearance: none;
    }

    .form-group {
        position: relative;
        width: 100%;
        max-width: 600px;
        margin-bottom: 0;
        line-height: 1;
        .icon {

            position: absolute;
            top: 25px;
            left: 15px;

            // width: 25px;
            height: 18px;

            z-index: 999;

            @include media-breakpoint-up(md) {
                left: 25px;
            }
            img{
                height: 18px;
            }
        }
    }

    label.error,
    .field-error {
        display: block;
        margin-bottom: 20px;
        margin-top: -10px;
        color: red;
        font-size: 1.5rem;
    }
    .form-messages {
        text-align: center;
        &.success {
            color: green;
        }
        &.error {
            color: red;
        }
    }
}