footer {
    background: #F6F7F9;
    .footer-logo img {
        max-width: 239px;
        padding-top: 33px;
        margin-bottom: 1.6rem;
    }
    .footer-row{
        color: #444B51;
        a{
            color: #444B51;
            text-decoration: none;
        }
        a:hover {
            color: #2b9182;
        }
    }
}