.ninety-nine-pi{


    &.pad-reduction{
        padding: 0px;
    }
    .highlight{
        color: #03B882;
    }
    background-color: #F6F7F9;
    .background-image-container{
        z-index: 0;
    }

    .after-img{


        max-width: 243.31px;
        background: #FFFFFF;
        box-shadow: 0px 4.73684px 4.73684px rgba(16, 16, 16, 0.1);
        border-radius: 1.73077px;

        font-weight: 700;
        font-size: 19.8824px;
        line-height: 26px;
        color: #03B882;
        mix-blend-mode: normal;
        margin: 20px 0;
        padding: 10px;
        img{
            margin: 0 10px;
            width: 15.05px;
            height: 15px;
        }
    }

.percent-blocks-wrapper{
    position: relative;
    z-index: 1;
}
    .percent-blocks{
    font-family: 'DMSans';
    text-align: center;
        .percent-wrapper{
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            margin: 15px 15px;
            // border: 1px red solid;
            padding: 20px 45px;
            .header{
                font-size: 80px;
                line-height: 104px;
                font-weight: 700;
                color: #181D2D;

            }
            .subhead{
                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    line-height: 28px;
                }
                font-size: 13px;
                line-height: 21px;
                font-weight: 400;
                color: #283F52;
            }
            .copy{
                font-size: 18px;
                line-height: 34px;
                @include media-breakpoint-up(md) {
                    font-size: 24px;
                    line-height: 45px;

                }
                .grn{
                    color: #03B882;
                    padding: 0 15px;
                    font-weight: 700;
                }
                .blk{
                    color: #040201;
                    padding: 0 15px;
                    font-weight: 700;
                }
                .btm-grad{
                    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                }

            }

        }
    }
}
section.ninety-nine-logo-banner{
    // padding: 0 0 0 25px;
    
    .logo{
        max-width: 218px;
        margin: 12px 10px;
        min-width: 160px;
        @include media-breakpoint-up(md) {
            max-width: 218px;
            // margin: 20px 20px;
        }
    }
}