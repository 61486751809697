.boost-guarantee{
    background-color: #fff;

    h2{
        max-width: 580px;
    }

    .guarantee-img{
        padding-bottom: 30px;
        max-width: 186px;
        @include media-breakpoint-up(md) {
            max-width: 365px;
        }
    }
    .btn-section{
        padding-top: 25px;
        
        @include media-breakpoint-up(md) {
            padding-top: 48px;
        }
    }
}