.fraction-blocks{
    h2{
        color: #fff;
    }
    .underline{
        border-bottom: 11px solid rgba(5, 182, 129, 0.5);
        display: inline-block;
        line-height: 16px;
        @include media-breakpoint-up(md) {
            line-height: 26px;
        }
    }
    .block-wrapper{
        // background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
        // border: 2px solid hsla(0,0%,100%,.2);
        background: radial-gradient(200% 150% at bottom left,hsla(0,0%,100%,.3) 0,hsla(0,46%,200%,0) 100%);
        border: 2px solid hsla(0,0%,100%,.2);
        box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(42px);
        border-radius: 5px;

        margin: 50px 0;
        // padding: 0 40px 20px 40px;
        // padding: 0px 0px 30px 50px;

        @include media-breakpoint-up(md) {
            margin: 16px 0;
            padding: 40px 0;
        }
        padding: 0px 10px 30px 0px;

        .icon{
            position: relative;
            left: 8px;
            top: 46px;
            width: 37px;

            @include media-breakpoint-up(md) {
                position: relative;
                width: 250px;
                left: -30px;
                top: 0px;
            }

            // position: relative;
            // left: 0px;
            // margin: auto;
            // top: -25px;
            // width: 50px;

            // @include media-breakpoint-up(md) {
            //     position: relative;
            //     width: 250px;
            //     left: -30px;
            //     top: 0px;
            // }
            // @include media-breakpoint-up(lg) {
            //     width: 250px;
            //     left: -43px;
            //     top: 0px;
            // }
            // @include media-breakpoint-up(xl) {
            //     width: 200px;
            //     left: -43px;
            //     top: 17px;
            // }
        }
        .copy{
            padding-left: 55px;
            @include media-breakpoint-up(md) {
                padding-left: 0px;
                font-size: 20px;
            }
            font-size: 18px;
            line-height: 29px;
            color: #FFFFFF;
            font-weight: 400;
            span{
                font-weight: 700;
            }
        }
    }
    .btn-subtext{
        color: #F0F8FF;
    }
}