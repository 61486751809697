body.ebook-page {
	.ebook-hero{
        .btn-copy{
            padding: 20px 0px;
            font-weight: 400;
            font-size: 18px;
            line-height: 29px;
            letter-spacing: 0.01em;
            color: #FFFFFF;
        }
    }

    ul.styled-list li img, ul.styled-list li svg {
        max-width: 27px;
    }
    .ninety-nine-pi{
        background-color: #F6F7F9;

        .background-image-container{
            z-index: 1;
        }
        h2{
            span{
                color: #03B882;;
            }
        }
        .after-img{
            max-width: 250px;   
        }

    }
    .popup-section{
        .modal-content{
            max-width: 582px;
            padding-bottom: 40px;
        }
        .modal-header{
            max-width: 420px;
            border-bottom: none;
            font-weight: 700;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
            color: #040201;
            padding-top: 40px !important; 



            @include media-breakpoint-up(md) {

            }
        }
        .modal-body{
            padding: 1rem;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #444B51;
            mix-blend-mode: normal;
            @include media-breakpoint-up(md) {
                padding: 10px 70px !important;
            }
        }
    }
}
section.ninety-nine-logo-banner{
    // padding: 0 0 0 25px;
    
    .logo{
        max-width: 218px;
        margin: 12px 10px;
        min-width: 160px;
        @include media-breakpoint-up(md) {
            max-width: 218px;
            // margin: 20px 20px;
        }
    }
}