.form-block{
    .underline{
        border-bottom: 11px solid rgba(5, 182, 129, 0.5);
        display: inline-block;
        line-height: 16px;
        @include media-breakpoint-up(md) {
            line-height: 10px;
        }
    }
    .foot-form-heading{
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            max-width: 784px;
            padding-bottom: 50px;
        }
    }
    h5{
        padding-bottom: 15px;
        color:  #168D69;
        line-height: 18px;
        letter-spacing: 0.03em;
    }
    .img{
        max-width: 970px;
        margin: 0 auto;
        @include media-breakpoint-up(md) {
            margin-bottom: 50px;
        }
        margin-bottom: 25px;

    }   
     p{
        // max-width: 600px;
        width: 90%;
        padding: 0px;
     }
     .btn, button, input[type="button"], input[type="submit"]{
        max-width: 600px !important;
     }
}