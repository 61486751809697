section {
    .hero-wrapper{

    }
    // Padding reduction on main copy blocks
    .copy-block{
        @include media-breakpoint-up(md) {
            padding: 0px;
        }
        padding: 0px 15px;
    }
}
.vp-center{
    display: none !important;
}
.tracking-test{
    font-family: 'Tracking';
 font-size: 40px;
}