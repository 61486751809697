.transform-yo-self{
  
    .heading{
        max-width: 720px;
    }
    .full-width-img{
        margin: auto;
        @include media-breakpoint-up(md) {
            max-width: 1140px;
        }
    }
}

