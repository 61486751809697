.class-testimonials{
    // height: 900px;
    .less-mob-padding{
        padding-left: 0px;
        padding-right: 0px;
        
        @include media-breakpoint-up(md) {
        }
    }
    .stars{
        max-width: 180px;
        padding-bottom: 15px;
    }
    h2{
        color: #fff;
    }
    .review-parent{
        display: flex;
        flex-wrap: wrap;
        // padding: 0 4px;

        .review-column {
            flex: 100%;
            max-width: 100%;
            @include media-breakpoint-up(md) {
              flex: 50%;
              max-width: 50%;
          }
        
          .review-wrapper{

            background: radial-gradient(200% 150% at top left,hsla(0,0%,100%,.3) 0,hsla(0,46%,200%,0) 100%);
        
            border: 1px solid rgba(255, 255, 255, 0.4);
            box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
            filter: drop-shadow(0px 45px 50px rgba(0, 0, 0, 0.5));
            backdrop-filter: blur(42px);
            border-radius: 5px;
            margin: 15px 15px;

            padding: 19px;
            @include media-breakpoint-up(md) {
                padding: 52px 38px 44px 43px;
            }
            .top-row{
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 15px;
                .profile{
                    max-width: 40px;
                    @include media-breakpoint-up(md) {
                        max-width: 65px;
                    }
                   
                }
                .reviewee{
                    // margin-left: 10px;
                    padding-left: 6px;
                    // max-width: 140px;
                    @include media-breakpoint-up(sm) {
                        max-width: 100%;
                        padding-left: 10px;
                    }
                    .name{

                        font-weight: 700;
                        font-size: 16px;
                        line-height: 18px;
                        color: #FFFFFF;
                        mix-blend-mode: normal;
                        @include media-breakpoint-up(md) {
                            font-size: 20px;
                            line-height: 21px;
                        }
                    }
                    .title{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                        color: rgba(255, 255, 255, 0.5);
                        @include media-breakpoint-up(md) {
                            font-size: 20px;
                            line-height: 21px;
                        }
                    }
    
                }
                .quote-icon{
                    // max-width: 40px;
                    width: 46px;
                    height: 43px;
                    @include media-breakpoint-up(lg) {
                        max-width: 100%;
                        width: 70px;
                        height: 70px;
                    }
                    // justify-content: flex-end;
                    // display: flex;
                }
            }
            .mid-row {
                padding-bottom: 15px;
                @include media-breakpoint-up(md) {
                    padding-bottom: 30px;
                }
                .copy{
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 28px;
                    color: #FFFFFF;
                    mix-blend-mode: normal;
                }
            }
            .btm-row{
    
                .review-stars{
                    img{
                        max-height: 100px;
                        @include media-breakpoint-up(md) {
                            max-height: 27.29px;
                            max-width: 141px;
                        }
                    }
                }
                .verified{
                    font-weight: 400;
                    line-height: 159.5%;
                    letter-spacing: -0.02em;
                    color: rgba(255, 255, 255, 0.63);
                    font-size: 9px;

                    @include media-breakpoint-up(md) {
                        font-size: 15px;
                    }
                    .shield-icon{
                        padding-right: 10px;
                        height: 15px;

                        @include media-breakpoint-up(md) {
                            height: 25px;
                        }
                    }
                    .ver-text{
                        position: relative;
                        top: 45%;
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                }
            }
          }
        }
      }
      
      
      
      
      
      /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
      @media (max-width: 600px) {
        .column-responsive {
          flex: 100%;
          max-width: 100%;
        }
      }
      .btn-section {
          .btn-subtext{
            color: #FFFFFF;
          }
          padding-top: 20px;
        @include media-breakpoint-up(md) {
            padding-top: 50px;
          }
      }
}