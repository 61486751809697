.hero-content{
    padding-top: 25px;
    @include media-breakpoint-up(md) {
        padding-top: 45px;
    }
    h1{
        margin: auto;
        font-size: 28px;
line-height: 33px;

        @include media-breakpoint-up(md) {
            max-width: 783px;
            line-height: 55px;
            font-size: 46px;
        }
    }
}