.feature-banner{
    .logo{
        img{
            image-rendering: -webkit-optimize-contrast; /* Chrome, Edge, Opera, and Safari */
            // image-rendering: crisp-edges;
        }
        margin: 0px;
        min-width: 135px;
        @include media-breakpoint-up(md) {
            max-width: 218px;
            margin: 20px 20px;
        }
    }
    border-bottom: 1px solid #E4E6EC;
}