.one-one-performance{
    .underline{
        border-bottom: 11px solid rgba(5, 182, 129, 0.5);
        display: inline-block;
        line-height: 16px;
        @include media-breakpoint-up(md) {
            line-height: 10px;
        }
    }
    .coach-img{
        max-width: 970px;
        margin-right: -30px !important;
        margin-left: auto !important;
        @include media-breakpoint-up(md) {
            margin-bottom: 50px;
        }
        margin-bottom: 25px;

    }   
     
}