.sports-testimonials{
  .sports-wrapper {
    width: 90%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    line-height: 1.5;
  }
  
  .testimonial {
    margin-bottom: 80px;
    display: flex; 
    flex-direction: column;
    background: #151826;
    color: #fff;
    position: relative;
    
    &__header-ylw {
      display: flex;
      position: relative;
      align-items: flex-end;
      z-index: 2;
      
      &::before{
        background: #FFB34D;
       
        display: block;
        content: ' ';
        height: 80px;
        width: 80px;
        position: absolute;
        left: -10px;
        top: 80px;
        border-radius: 100%;
        z-index: -1;
        @media (max-width: 768px) {
          top: 30px;
        }
      }
    }
    &__header-grn {
      display: flex;
      position: relative;
      align-items: flex-end;
      z-index: 2;
      
      &::before{
        background: #04B882;
       
        display: block;
        content: ' ';
        height: 80px;
        width: 80px;
        position: absolute;
        left: -10px;
        top: 80px;
        border-radius: 100%;
        z-index: -1;
        @media (max-width: 768px) {
          top: 30px;
        }
      }
    }

    
    &__image {
      width: 160px;
      height: 160px;
      overflow: hidden;
      border-radius: 100%;
      background: linear-gradient(rgba(255,255,255,0.2), rgba(0,0,0,0.2));
      box-shadow: inset 0px 0px 2px rgba(255,255,255,.2);
      padding: 15px;
      margin-top: -60px;
      margin-left: 20px;
      
      position: initial;
  
      @media (max-width: 768px) {
        position: absolute;
        top:0;
      }
      
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 100%;
      }
    }
    &__meta {
      padding: 20px;
      @media (max-width: 768px) {
        padding-top: 123px;
      }
      h4 {
        font-size: 22px;
        line-height: 29px;
        color: #04B882;
        font-weight: 700;
      }
      p {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 14px;
      }
      img {
        width: 150px;
      }
    }
    
    &__content {
      position: relative;
      padding: 20px 40px 70px 20px;
      box-shadow: inset 0px 0px 3px rgba(255,255,255,0.3);
      background: linear-gradient(rgba(100,100,100,.3),rgba(255,255,255,0.2));
      left: 20px;
      top: 20px;
      border-radius: 3px;
      margin-bottom: -20px;
      
      font-size: 16px;
      line-height: 25px;
    }
    
    &::before {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='219' height='260' viewBox='0 0 219 260' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1197_4731' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='17' y='0' width='202' height='260'%3E%3Crect x='17' width='202' height='260' rx='10' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1197_4731)'%3E%3Cpath d='M105.015 147.244H185.725L214.211 13.4462H151.964L105.015 147.244ZM0.565735 147.244H81.2762L109.762 13.4462H47.515L0.565735 147.244Z' fill='url(%23paint0_linear_1197_4731)' fill-opacity='0.3'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1197_4731' x1='107.388' y1='13.4462' x2='107.388' y2='147.244' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2304B882'/%3E%3Cstop offset='1' stop-color='%2304B882' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      background-size: 100% auto;
      display: block;
      height: 200px;
      width: 200px;
      content: ' ';
      position: absolute;
      right: -50px;
      top: -50px;
    }
  }
        

   

}