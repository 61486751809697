// --------------------
// Include Fonts Here
// --------------------
@font-face {
    font-family: 'DMSans';
    src: url('../fonts/dmsans-400.woff2') format('woff2'),
    url('../fonts/dmsans-400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'DMSans';
    src: url('../fonts/dmsans-700.woff2') format('woff2'),
    url('../fonts/dmsans-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

$headings-font: 'DMSans', sans-serif;
$body-font: 'DMSans', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    @include font-smoothing('on');
    font-size: 2rem; //Laptop: 20px, Ipads: 18px, Mobiles: 16px
    line-height: 1.6;
    font-family: $body-font;
}

p {
    margin-bottom: 1.6rem;
    font-size: 1.8rem;
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.3;
    // margin-bottom: 2rem;
    font-family: $headings-font;
    // font-weight: bold;
    text-transform: capitalize;
}

h1, .h1 {
    font-weight: 700;
    font-size: 4.6rem;
    color: #FFFFFF;
    font-size: 28px;
    line-height: 33px;
    @include media-breakpoint-up(md) {
        line-height: 55px;
        font-size: 5rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    }
}

h2, .h2 {
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 29px;
    color: #181D2D;
    @include media-breakpoint-up(md) {
        font-size: 3.6rem;
        line-height: 45px;
    }
    // font-size: 4.6rem;  //Laptop: 46px, Ipads: 41.4px, Mobiles: 36.8px
    // padding-top: 3rem;

    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: calc(50% - 40px);
    //     width: 80px;
    //     height: 1px;
    //     background: $primary;
    // }
    // @include media-breakpoint-up(lg) {
    //     &.text-lg-left:before {
    //         left: 0;
    //     }
    // }
    // &.text-white:before {
    //     background: white;
    // }
}

h3, .h3 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 22px;
    color: #181D2D;

    
    // font-size: 3rem;  //Laptop: 30px, Ipads: 27px, Mobiles: 24px
}

h4, .h4 {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 45px;
    color: #040201;
    
    // font-size: 2.4rem;  //Laptop: 24px, Ipads: 21.6px, Mobiles: 19.2px
}

h5, .h5 {
    font-size: 18px;
    line-height: 5.5rem;
    letter-spacing: 0.03em;
    color: #444B51;
    
    // font-size: 2rem;  //Laptop: 20px, Ipads: 18px, Mobiles: 16px
}

h6, .h6 {
    font-weight: 700;
    font-size: 15px;
    line-height: 3.5rem;
    letter-spacing: 0.49em;

    // Hack to get text color as linear gradient 
    background: linear-gradient(110.29deg, #02165F 0.01%, #168D69 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block
  
    // font-size: 1.8rem;  //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
}

strong {
	font-family: $headings-font;
}

// Hero Specific Font Sizes
.eyebrow{
   h4{
    font-family: 'DMSans';
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03em;
    padding-top: 10px;

    @include media-breakpoint-up(md) {
        font-size: 18px;
        // line-height: 55px;
        letter-spacing: 0.03em;
    }
   }
}
.eyebrow-wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;

    img{
        width: 12px;
        height: 12px;
        margin: 0 9px; 
    }
}

.subheadline {
    font-size: 2.2rem;
}

.output{
    text-transform: uppercase;
}

.underline-pseudo{
    background-image: url(../images/span-bg.png);
    background-size: 100% 11px;
    background-repeat: no-repeat;
    background-position: center bottom 3px;
}

