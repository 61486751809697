.sales-testimonials{
    background: #F6F7F9;
    .underline{
        border-bottom: 11px solid rgba(5, 182, 129, 0.5);
        display: inline-block;
        // line-height: 16px;
        @include media-breakpoint-up(md) {
            line-height: 10px;
        }
    }
    .header-stars{
        max-width: 180px;
    }
    .video-parent{
        .video-wrapper{
            // box-shadow: 0 15px 10px #777;
            box-shadow: 0 14px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        
            padding: 0px !important;
            background-color: #fff;
            margin: 25px 10px;
            .video{
                .video-preview-container {
                    // position: inherit;
                    position: relative;

                    .play-button{
                        top: 40px;
                        @include media-breakpoint-up(sm) {
                            top: 70px;
                        }
                        @include media-breakpoint-up(md) {
                            top: 30px;
                        }
                        @include media-breakpoint-up(xl) {
                            top: 80px;
                        }
                    }
                }
            }
            .testimonials{
                line-height: 20px;
                color: #000000;
                padding: 30px 40px 30px 30px;
                .copy{

                }
                .details{
                    margin-left: 0px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: space-between;

                    @include media-breakpoint-up(md) {
                        flex-direction: row;
                    }
                    .name{
                        padding: 0px;
                    }
                    .stars{
                        padding: 0px;

                        margin-bottom: 1.6rem;
                        img{
                          width: 90px;
                            // max-height: 27.29px;
                        }
                    }
                }
            }
        }
    }
    .btn-section{
        padding-top: 40px;
        @include media-breakpoint-up(md) {
            padding-top: 90px;
        }
    }
}
