.five-step-block{
    .underline{
        border-bottom: 11px solid rgba(5, 182, 129, 0.5);
        display: inline-block;
        line-height: 16px;
        @include media-breakpoint-up(md) {
            line-height: 7px;
        }
    }
    color: #FFFFFF;
    .five-step-bg{
        overflow: hidden;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        pointer-events: none;
    }
    h2{
        color: #FFFFFF;
    }
    .wrapper{

        background: radial-gradient(200% 150% at bottom left,hsla(0,0%,100%,.3) 0,hsla(0,46%,200%,0) 100%);
        border: 2px solid hsla(0,0%,100%,.2);
        border-radius: 5px;
        -webkit-box-shadow: inset -5px -5px 250px hsla(0,0%,100%,.02);
        box-shadow: inset -5px -5px 250px hsla(0,0%,100%,.02);

        padding-bottom: 30px;
        padding-right: 15px;
        padding-left: 70px;
        
        @include media-breakpoint-up(md) {
            padding-left: 40px;
            padding-right: 40px;
            min-height: 440px;
            padding-bottom: 50px;
        }
    }
    .icon-wrap{
        margin-bottom: 0px;
        margin-top: 15px;

        background: #fff;
        height: 74px;
        width: 74px;
        border-radius: 100%;
        .icon{
            font-weight: 700;
            padding-top: 5px;
            display: flex;
            justify-content: center;
            align-content: center;
            font-size: 40px;
            color:  #03B882;
        }
    }
    .icon-wrap-mob{
        background: #fff;
        height: 37px;
        width: 37px;
        border-radius: 100%;
        position: absolute;
        left: 32px;
        top: 23px;

        .icon-mob{
            font-weight: 700;
            font-size: 20px;
            line-height: 21px;
            color: #03B882;
            display: flex;
            justify-content: center;
            align-content: center;
            padding-top: 8px;
        }
    }
   .subhead{
        font-weight: 700;
        padding-bottom: 10px;
        padding-top: 17px;
        font-size: 18px;
        line-height: 23px;

        @include media-breakpoint-up(md) {
            padding-bottom: 22px;
            max-width: 300px;
            font-size: 20px;
            line-height: 29px;
        }
    }
   .copy{
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        @include media-breakpoint-up(md) {
            font-weight: 400;
            font-size: 18px;
            line-height: 29px;
            letter-spacing: 0.01em;
        }
    }
    .btn-subtext{
        color: #FFFFFF;
    }
}
