.check-list-discover{

    p{
        font-size: 18px;
    }
    h2{
        padding-bottom: 50px;
        @include media-breakpoint-up(md) {
            // padding-bottom: 50px;
        }
    }
}