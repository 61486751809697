// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {

    border-radius: 50px;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: #fff;
    // background: linear-gradient(110.29deg, #06B681 0.01%, #02B882 100%);
    background-color: #03B882;
    font-weight: 700;
    width: 100%;
    border: 0;
    margin-bottom: 12px;
    line-height: 1;
    padding: 20px 10px;
    white-space: normal;
    max-width: 452px;
    // transition: 0.3s;
    transition: .4s ease-in-out;

    @include media-breakpoint-up(md) {
        padding: 24px;
    }

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        &:hover{
            // background: linear-gradient(180deg, #F99A2E 0%, #F36914 100%);
            background-color: #F36914;
        }
    }
}

.btn-section {
    p {
        width: 90%;
        margin: 0 auto;
    }
}
.btn-subtext{
    max-width: 375px;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #444B51;
}