.t-and-t-framework{
    background-color: #fff;

  
    .icon-section-container{
        max-width: 720px;
        padding-bottom: 40px;
        @include media-breakpoint-up(md) {
            padding-bottom: 60px;
        }
    }
    .copy-block{
        @include media-breakpoint-up(md) {
            padding: 0px;
        }
        padding: 0px 15px;
        p{
            margin: 3rem 0 3rem 0;
        }
    }
    .icon-content-wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .icon-content{
            display: flex;
            flex: 100%;
            justify-content: center;
            padding: 10px;
            
            @include media-breakpoint-up(md) {
                flex: 50%;
            }
            
            .icon{
                max-width: 60px;
            }
            .copy{
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #181D2D;

                display: flex;
                align-content: center;
                align-items: center;
                max-width: 240px;
                margin: 0px 15px;
                @include media-breakpoint-up(md) {
                    font-size: 20px;
                }
            }
        }
    }
}